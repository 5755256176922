.terms-con{
    padding: 10px 20px !important;
    text-align: justify;
    font-weight: 500;
}
.UT7-form-box{
    padding: 20px !important;
    /* height: fit-content; */
}
.fit_content{
    height: fit-content;
}