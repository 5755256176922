.closedBanner{
    object-fit: cover;
    width: 80%;
    height: auto;
    /* z-index: 99999999; */
    margin: 20px;
}

.clod{
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    /* overflow: hidden; */
}

.clod-2{
    overflow: visible !important;
}

.UT7-form-box-selection{
    z-index: 999;
    height: auto !important;
}

.UT7-form-box{
    z-index: 999;
}

.btn-warning{
    background-color: #FEF200;
    color: #000;
}

.UT7-header{
    z-index: 999;
}

.UT7-selection-logo{
    margin-top: 10px;
    margin-bottom: 30px;
}

.UT7-form-wrap-org {
    margin-bottom: 5px;
    padding-bottom: 0px;
}

.UT7-form-sub-heading{
    text-decoration: underline;
    text-decoration-color: red;
}