*{
  box-sizing: border-box;
}
body{
  padding: 0px;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  background: #FEF200 ;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(157, 65, 12, 0.8) #FEF200;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: #FEF200;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(157, 65, 12, 0.8);
  border-radius: 20px;
}
.lft-bg{
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: url("./assets/img/left-bg-graphics.png") no-repeat left center;
  background-size: 38%;
  opacity: 0.3;
  z-index: 0 !important;
}
.rgt-bg{
  width: 50%;
  height: 50%;
  position: fixed;
  top: 0px;
  right: 0px;
  background: url("./assets/img/right-bg-graphics.png") no-repeat right top;
  opacity: 0.15;
}
p{
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

a{
  text-decoration: none;
}

/* common css start */
.UT7-section-hdng{
  width: auto;
  position: relative;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}
.gallery-body{
  z-index: 9999;
}
@media (min-width:1366px){
  .container-fluid{
      padding-left: 50px;
      padding-right: 50px;
  }
}
.UT7-body-area{
  width: 100%;
  position: relative;
  z-index: 1;
  /* padding-top: 152px; */
  min-height: calc(100vh - 195px);
}
@media (max-width:991px){
  body{
      padding-bottom: 85px;
  }
  .UT7-body-area{
      padding-top: 20px;
      min-height: calc(100vh - 85px);
  }
  .lft-bg {
      width: 50%;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      background: url("./assets/img/left-bg-graphics.png") no-repeat left 23%;
      background-size: 104%;
      opacity: 0.2;
      z-index: 0 !important;
  }
  .rgt-bg {
      width: 30%;
      height: 50%;
      position: fixed;
      top: 0px;
      right: 0px;
      background: url("./assets/img/right-bg-graphics.png") no-repeat right top;
      opacity: 0.15;
      background-size: 124%;
  }
}
/* common css ended */


/* header css start */
.UT7-header{
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 20px 0px;
  /* z-index: 21; */
  z-index: 1;
}
.UT7-hdr-full-col{
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 33px;
  width: 100%;
}
.UT7-hdr-logo-link{
  position: relative;
}
.UT7-hdr-logo{
  max-width: 153px;
}
@media (max-width:991px){
  .UT7-header{
      position: fixed;
      padding: 0px;
      top: auto;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 21;
  }
  .UT7-header .container-fluid{
      padding: 0px;
  }
  .UT7-hdr-logo-link{
      display: none;
  }
}
/* header css ended */


/* header menu css start */
.UT7-hdr-nav{
  width: auto;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: inline-flex;
  gap: 34px;
}
.UT7-hdr-nav li{
  position: relative;
}
.UT7-hdr-nav li a{
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  padding: 3px 0px;
  border: 1px solid transparent;
}

.UT7-hdr-nav li a img{
  width: 15px;
  display: none;
}

.UT7-hdr-nav li.active a{
  border: 1px solid #343434;
  border-width: 1px 0px;
}

/*? NavLink active for WEB */
.UT7-hdr-nav li a.active{
  border: 1px solid #343434;
  border-width: 1px 0px;
}
/*? NavLink active for WEB */
.UT7-hdr-nav li.menu-logo{
  display: none;
}
@media (max-width:991px){
  .UT7-hdr-nav{
      width: 100%;    
      background: #FEF200;
      justify-content: space-between;
      gap: 0px;
      border-top: #000000 solid 2px;
  }
  .UT7-hdr-nav li{
      width: 100%;
  }
  .UT7-hdr-nav li a{
      width: 100%;
      height: 65px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border-width: 1px 1px 0px 0px;
      border-right-color: #fede00;
  }
  .UT7-hdr-nav li:last-child a{
      border-right: none;
  }
  .UT7-hdr-nav li a img{
      width: 20px;
      display: inline-block;
  }
  /*? NavLink active for MWEB */
  .UT7-hdr-nav li a.active{
    color: #fff;
    background: #000;
  }

  .UT7-hdr-nav li a.active img{
    filter: invert(1);
  }
  /*? NavLink active for MWEB END*/
  .UT7-hdr-nav li.active a{
      color: #fff;
      background: #000;
  }
  .UT7-hdr-nav li.active a img{
      filter: invert(1);
  }
  .UT7-hdr-nav li.menu-logo{
      display: inline-block;
      margin-top: 0px;
      padding: 0px 10px;
  }
  .UT7-hdr-nav li.menu-logo img{
      width: 80px;
  }
}
@media (max-width:400px){
  .UT7-hdr-nav li a {
      font-size: 10px;
  }
  .UT7-hdr-nav li a img {
      width: 13px;
  }
}
/* header menu css ended */


/* footer css start */
.UT7-footer{
  width: 100%;
  position: relative;
}
.UT7-footer-box{
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border-top: #FED500 solid 1px;
  color: #C9AB7E;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width:767px){
  .UT7-footer{
      display: none;
  }
}
/* footer css ended */


/* home page css start */
.UT7-home-wrapper{
  width: 100%;
  display: inline-flex;
  position: relative;
  gap: 15px;
}
.UT7-home-lft-col{
  position: relative;
  flex: 1;
}
.UT7-home-lft-col p{
 text-align: justify;
}
.UT7-home-rgt-col{
  position: relative;
  text-align: center;
}
@media (max-width:991px){
  .UT7-home-wrapper{
      flex-direction: column-reverse;
      align-items: center;
  }
}
/* home page css ended */


/* home yt video css start */
.UT7-home-yt-frame{
  width: 100%;
  position: relative;
  overflow: hidden;
  /*padding-bottom: 56.25%; *//* 16:9 */
  padding-bottom: 36.25%;
  height: 0;
  border-radius: 16px;
  margin-bottom: 15px;
  margin-top: 20px;
}
.UT7-home-yt-frame iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
@media (max-width:1199px){
  .UT7-home-yt-frame{
      padding-bottom: 56.25%;/* 16:9 */
  }
}
@media (max-width:991px){
  .UT7-home-rgt-col{
      margin-bottom: 30px;
  }
}
/* home yt video css ended */


/* chachitra section css start */
.UT7-chalchitra-full-wrap{
  max-width: 95%;
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  margin-top: -100px;
  text-align: center;
}
.UT7-chalchitra{
  width: auto;
  position: relative;
}
.UT7-chalchitra-design{
  /* width: 90%;
  max-width: 431px; */
  min-width: calc(100vh - 390px);
}
.UT7-chalchitra-side-decor{
  position: relative;
  z-index: 1;
}
.UT7-chalchitra-side-decor.left{
  margin-right: -117px;
  margin-bottom: -10px;
}
.UT7-chalchitra-side-decor.right{
  margin-left: -125px;
  margin-bottom: -15px;
}
.UT7-chalchitra-content{
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 100px 0px 0px 0px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.UT7-chalchitra-content img{
  width: auto;
  max-width: 90%;
}
.UT7-register-btn{
  width: auto;
  height: 46px;
  border-radius: 30px;
  background: #DD0800;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding: 5px 25px;
  border: none;
}
.UT7-chalchitra-full-wrap.new{
  width: 620px;
  margin-top: -22px;
}
.UT7-chalchitra-full-wrap.new .UT7-chalchitra-content{
  padding: 0px;
  position: relative;
  transform: none;
  left: 0;
  gap: 15px;
}
.UT7-chalchitra-full-wrap.new .UT7-chalchitra{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UT7-chalchitra-full-wrap.new .UT7-durga-eye{
  width: 300px;
}
.UT7-chalchitra-full-wrap.new .UT7-banner-tag{
  width: 400px;
}

@media (max-width:1500px){
  .UT7-chalchitra-full-wrap.new{
    width: 520px;
  }
  .UT7-chalchitra-full-wrap.new .UT7-durga-eye{
    width: 250px;
  }
  .UT7-chalchitra-full-wrap.new .UT7-banner-tag{
    width: 350px;
  }

}

@media (max-width:991px){
  .UT7-chalchitra-full-wrap{
      margin-top: 0px;
  }
  .UT7-chalchitra-design{
      width: 90%;
      max-width: 431px;
  }
  .UT7-chalchitra-full-wrap.new{
    width: 100%;
  }
  .UT7-chalchitra-full-wrap.new .UT7-chalchitra-content{
    padding-top: 20px;
  }
  .UT7-chalchitra-full-wrap.new .UT7-durga-eye{
    width: 250px;
  }
  .UT7-chalchitra-full-wrap.new .UT7-banner-tag{
    width: 360px;
  }
}

@media (max-width:767px){
  .UT7-chalchitra-side-decor.right {
      width: 100px;
      margin-left: -125px;
      margin-bottom: -4px;
      position: absolute;
      right: 0px;
  }
  .UT7-chalchitra-side-decor.left {
      width: 73px;
      margin-right: -59px;
      margin-bottom: -10px;
      position: absolute;
  }
  .UT7-register-btn {
      width: 70%;
      height: 38px;
      font-size: 14px;
  }
}
@media (max-width:480px){
  .UT7-chalchitra-content img {
      width: 45%;
      max-width: 90%;
  }
}
/* chachitra section css ended */


/* gallary page css start */
.UT7-gallery-body-section{
  width: 100%;
  position: relative;
  display: inline-flex;
  /* padding-top: 15px; */
  gap: 50px;
}
@media (max-width:991px){
  .UT7-gallery-body-section{
      flex-direction: column;
      align-items: flex-start;
  }
}
/* gallary page css ended */


/* gallery filter section css start */
.UT7-gallery-filter-box{
  width: 244px;
  min-height: 246px;
  flex-shrink: 0;
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(157, 65, 12, 0.35);
  background: rgba(255, 252, 189, 0.34);
}
.UT7-gallery-filter-hdng{
  width: 100%;
  display: inline-block;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.464px;
  border-bottom: 1px solid rgba(157, 65, 12, 0.35);  
  padding: 5px 15px;
  margin-bottom: 8px;
}
.UT7-gallery-filter-hdng .back{
  width: auto;
  display: none;
}
.UT7-gallery-filter-hdng .back img{
  width: 16px;
  filter: invert(1);
}
.UT7-gallery-filter-hdng .down{
  width: auto;
  display: none;
}
.UT7-gallery-filter-hdng .down img{
  width: 16px;
}
.UT7-gallery-filter-hdng .down.up img{
  transform: rotate(180deg);
}
.UT7-gallery-filter-list{
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 8px 0px;
  margin: 0px;
  list-style-type: none;
  gap: 2px;
}
.UT7-gallery-filter-list li{
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.406px;
}
.UT7-gallery-filter-list li a{
  width: 100%;
  min-height: 34px;
  padding: 5px 10px 5px 15px;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.406px;
  background: rgba(255, 251, 163, 0.54);
  text-decoration: none;
}
.UT7-gallery-filter-list li.active a{
  background-color: #fff;
}
.UT7-gallery-filter-list li img{
  width: 11px;
}
.UT7-gallery-filter-list li.back a{
  background: rgba(0,0,0,0.8);
  color: #fff;
}

@media (max-width:991px){
  .UT7-gallery-filter-box{
      width: calc(100% - 25px);
      position: fixed;
      top: 10px;
      background: rgb(254 242 0);
      z-index: 2;
      min-height: 10px;
  }
  .UT7-gallery-filter-hdng{
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
  }
  .UT7-gallery-filter-hdng .back{
      display: inline-block;
  }
  .UT7-gallery-filter-hdng .down{
      display: inline-block;
  }
  .UT7-gallery-filter-box .UT7-gallery-filter-hdng{
      margin-bottom: 0px;
      border-bottom: none;
  }
  .UT7-gallery-filter-box .UT7-gallery-filter-list,
  .UT7-gallery-filter-box .UT7-gallery-filter-list li.back{
      display: none;
  }
  .UT7-gallery-filter-box .UT7-gallery-filter-list.mobileshow{
      display: inline-flex;
  }
}
/* gallery filter section css ended */


.UT7-gallery-page-title{
  width: 100%;
  display: inline-block;
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.464px;
  border-bottom: 1px solid rgba(157, 65, 12, 0.35);
  padding: 0px 0px 15px 0px;
  margin-bottom: 20px;
}


/* gallery cards section css start */
.UT7-gallery-cards-area{
  width: 100%;
  max-height: calc(100vh - 250px);
  padding:5px 15px 5px 5px;
  overflow: hidden;
  overflow-y: auto;
}
.UT7-gallery-cards-area>.row{
  margin-left: -20px;
  margin-right: -20px;
}
.UT7-gallery-cards-area>.row>div[class*='col-']{
  padding-left: 20px;
  padding-right: 20px;
}
.UT7-gallery-card{
  width: 100%;
  height: 230px;
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  /* overflow: hidden; */
  cursor: pointer;
}
.UT7-gallery-card .UT7-gallery-card-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  opacity: 0;
  z-index: 1;
  background-color: rgba(0,0,0,0.6);
  transition: all ease-in-out 0.3s;
}
.UT7-gallery-card .UT7-gallery-card-overlay img{
  width: 50px;
}
.UT7-gallery-card:hover .UT7-gallery-card-overlay{
  opacity: 1;
}
.UT7-gallery-card.auto{
  height: auto;
  box-shadow: #fff 0px 0px 0px 5px;
}
.UT7-gallery-card.auto.active,
.UT7-gallery-card.auto:hover{
  box-shadow: #0a0a0a 0px 0px 0px 5px;
}
.UT7-gallery-card.active,
.UT7-gallery-card:hover{
  box-shadow: #fff 0px 0px 0px 5px;
}
.UT7-gallery-card .UT7-card-thumb{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: all ease-in-out 0.4s;
}
.UT7-gallery-card.auto .UT7-card-thumb{
  height: auto;
}
.UT7-gallery-card.auto:hover .UT7-card-thumb{
  transform: scale(1.0, 1.0) ;
}
.UT7-gallery-card:hover .UT7-card-thumb{
  transform: scale(1.1, 1.1) ;
}
.UT7-gallery-card-cnt-overlay{
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 7px;
  z-index: 1;
}
.UT7-gallery-card-cnt{
  width: 100%;
  min-height: 53px;
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
  border-radius: 6px;
  background: rgba(11, 11, 11, 0.76);
  color: #FFF;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UT7-gallery-card-cnt img{
  height: 53px;
  border-radius: 6px;
  flex-shrink: 0;
}
.UT7-card-title{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.UT7-gallery-card.year{
  width: 100%;
  overflow: hidden;
  display: flex;
  background: #FEF200;
  box-shadow: #fff 0px 0px 0px 5px;
}
.UT7-gallery-card.year .UT7-gallery-card-cnt-overlay{
  height: 100%;
}
.UT7-gallery-card.year .UT7-gallery-card-cnt{
  height: 100%;
  position: relative;
  background: transparent;
  justify-content: center;
}
.UT7-gallery-card.year .UT7-card-title{
  text-align: center;
  color: #fff;
  font-weight: 600;
  width: auto;
  padding: 5px 15px;
  font-size: 50px;
  border-radius: 10px 0px 10px 0px;
  background-color: #000;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.UT7-gallery-card.year .UT7-card-title small{
  font-size: 14px;
  display: inline-flex;
  padding: 4px 15px;
  background: #000;
  color: #fff;
}


.UT7-gallery-card-popup{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
.UT7-gallery-card-popup.show{
  display: block;
}
.UT7-gallery-card-popup-box{
  width: 95%;
  max-width: 980px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  padding: 10px;
  border-radius: 16px;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 15px;
}
.UT7-gallery-card-popup-content{
  flex: 1;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px;
  gap: 10px;
}
.UT7-gallery-card-popup-img{
  width: 100%;
  max-width: 410px;
  border-radius: 16px;
}
.UT7-gallery-card-popup-hdng{
  width: 100%;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 10px 10px 0px;
  border-bottom: #FDB600 solid 1px;
  margin-bottom: 10px;
}
.UT7-gallery-card-popup-theme-title{
  width: 100%;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}
.UT7-gallery-card-popup-content p{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.UT7-gallery-card-popup-close{
  width: 30px;
  height: 30px;
  background-color: #000000;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 2;
  cursor: pointer;
}
.UT7-gallery-card-popup-close img{
  width: 20px;
}
@media (max-width:991px){
  .UT7-gallery-page-title {
      font-size: 20px;
      padding-top: 30px;
  }
  .UT7-gallery-card{
      margin-bottom: 60px;
  }
  .UT7-gallery-cards-area {
      max-height: inherit;
      padding: 5px 5px 5px 5px;
  }
  .UT7-gallery-card-popup-box{
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 991px;
      top: 0px;
      left: 0%;
      border-radius: 0px;
      transform: translate(0%, 0%);
      height: calc(100vh - 67px);
      overflow: hidden;
      overflow-y: auto;
  }
  .UT7-gallery-card-popup-hdng {
      font-size: 17px;
  }
  .UT7-gallery-card-popup-box .UT7-gallery-card-popup-img{
      max-width: 210px;
      border-radius: 0px;
  }
}
/* gallery cards section css ended */



/* form page css start */
.UT7-form-body-area{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.UT7-form-box{
  width: 100%;
  max-width: 944px;
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.UT7-form-box-hdng{
  width: auto;
  position: relative;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36.696px;
  padding: 15px 0px 10px 0px;
  border-bottom: rgba(255, 242, 0, 0.50) solid 2px;
  margin-bottom: 35px;
}
.UT7-form-box-hdng:after{
  content: '';
  width: 124px;
  height: 5px;
  background: #FFF200;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}
.UT7-form-wrap{
  width: 100%;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding: 0px 65px 30px;
  position: relative;
}
@media (max-width:991px){
  .UT7-form-box {
      height: calc(100vh - 125px);
  }
  .UT7-form-box-hdng {
      font-size: 16px;
      padding: 10px 0px 5px 0px;
  }
}
/* form page css ended */


/* form field css start */
.UT7-form-field-row{
  width: 100%;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 25px;
}
.UT7-form-field-row .UT7-form-title{
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.406px;
  padding: 0px;
  margin: 0px;
}
.UT7-form-input-row{
  width: 100%;
  position: relative;
}
.UT7-form-input{
  width: 100%;
  height: 59px;
  border-radius: 5px;
  border: 2px solid #F0EDED;
  background: #FFF;
  appearance: none;
  padding: 19px;
  color: #0D0D0D;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  outline: none;
}
select.UT7-form-input{
  background: #fff url("./assets/img/arrow-down-gary-icon.svg") no-repeat 95% center;
}
textarea.UT7-form-input{
  height: 105px;
  resize: none;
}
.UT7-form-input::placeholder{
  opacity: 1;
  color: #BCBCBC;
}
.UT7-form-input:focus{
  border: 2px solid #FEF200;
}
.red-text{
  color: #FF0D0D !important;
}
.UT7-form-msg{
  color: #252525;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 152.9%; /* 18.348px */
}
.UT7-form-msg.error{
  color: #F82D2D;
}
.UT7-form-msg.success{
  color: #219c3b;
}
.UT7-form-field-row .UT7-form-msg{
  position: relative;
}
.UT7-form-action-row{
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
}
.submit-btn{
  min-width: 200px;
}
.UT7-custom-checkbox{
  width: auto;
  padding: 0px;
  margin: 0px 0px 5px 0px;
  position: relative;
  color: #6C6B6B;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
}
.UT7-custom-checkbox input{
  position: absolute;
  opacity: 0;
}
.UT7-custom-checkbox span{
  display: inline-flex;
  gap: 9px;
}
.UT7-custom-checkbox span:before{
  content: '✔';
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #FC0;
  background: #FFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: transparent;
  margin-top: -2px;
}
.UT7-custom-checkbox input:checked + span:before{
  color: #fff;
  background-color: #FC0;
}
.note{
  font-size: 12px !important;
  color: #a16a06 !important;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.note img{
  width: 14px;
}
@media (max-width:991px){
  .UT7-form-wrap {
      padding: 0px 15px 30px;
  }
  .UT7-custom-checkbox {
      font-size: 12px;
      margin-bottom: 30px;
  }
}
/* form field css ended */



/* table css start */
.UT7-table{
  width: 100%;
  border: #d1d1d1 solid 1px;
  background: #fff;
  border-radius: 10px;
}
.UT7-table th{
  background: #0D0D0D;
  color: #fff;
  padding: 10px 20px;
  text-align: left;
  font-size: 14px;
}
.UT7-table td{
  padding: 10px 20px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  border-right: #d1d1d1 solid 1px;
  border-bottom: #d1d1d1 solid 1px;
}
.UT7-table td:last-child{
  border-right: none;
  width: 300px;
}
.UT7-table tr:last-child td{
  border-bottom: none;
}
.UT7-table-btn-group{
  display: inline-flex;
  gap: 10px;
}
/* table css ended */




/* about us page css start */
.UT7-about-sldr-img-frame{
  width: 100%;
  /* height: 500px; */
  overflow: hidden;
  border: #ffffff solid 4px;
  box-shadow: inset #fff200 0px 0px 0px 4px;
}
.UT7-about-sldr-img-frame img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.UT7-about-banner-slider{
  margin-bottom: 40px;
}
.UT7-about-banner-slider .carousel-indicators{
  bottom: -50px !important;
}
.UT7-about-banner-slider .carousel-indicators button{
  width: 10px !important;
  height: 10px !important;
  border-radius: 40px !important;
  background: #000 !important;
  margin: 10px 2px !important;
  border: none !important;
  opacity: 0.2 !important;
}
.UT7-about-banner-slider .carousel-indicators button.active{
  opacity: 1 !important;
}
/* about us page css ended */