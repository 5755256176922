html, body {
    /* margin: 40px auto; */
    /* font-family: "Roboto", sans-serif; */
    /* height: 100%;
    overflow: hidden ; */
    background: #fff200;
    font-weight: 100;
    /* -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; */
  }
  .underdev{
     margin: 55px auto !important;
  }
  
  main {
    height: 100%;
    display: flex;
    margin: 0 20px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  main h1 {
    font-size: 3em;
    font-weight: 100;
    color: #000;
    margin: 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  main h2 {
    font-size: 1.5em;
    font-weight: 100;
    margin-bottom: 0;
  }
  main h3 {
    font-size: 1.5em;
    font-weight: 100;
    margin-top: 0;
  }
  main a {
    font-size: 1.5em;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
  
  footer {
    display: table;
  
   
    font-weight: 300;
    text-align: center;
      margin-left: auto;
      margin-right: auto;
    
  }
  footer h2{
    text-align: center;
    display: none;
  }
  .bg-ov{
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border: 0px solid transparent;
    border-radius: 20px;
    margin: 10px;
  }