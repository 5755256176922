.terms{
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: #C9AB7E;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.row{
    /* align-items: center; */
    /* text-align: center; */
}
.madeBy{
    display: block;
}
.noLink{
    text-decoration: none;
    color: #C9AB7E;
    /* display: none; */
}