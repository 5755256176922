.UT7-gallery-body-section.failed{
    justify-content: center;
    margin-top: 10%;
}
.card {
    overflow: hidden;
    position: relative;
    background-color: #ffffff;
    text-align: left;
    border-radius: 0.5rem;
    max-width: 600px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  
  .header {
    padding: 1.25rem 1rem 1rem 1rem;
    background-color: #ffffff;
  }
  
  .image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #FEE2E2;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 9999px;
  }
  
  .image svg {
    color: #DC2626;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .content {
    margin-top: 0.75rem;
    text-align: center;
  }
  
  .title {
    color: #111827;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
  
  .message {
    margin-top: 0.5rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 20px;
  }
  
  .actions {
    margin: 0.75rem 1rem;
    background-color: #F9FAFB;
  }
  
  .desactivate {
    display: inline-flex;
    padding: 0.5rem 1rem;
    background-color: #DC2626;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: transparent;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .cancel {
    display: inline-flex;
    margin-top: 0.75rem;
    padding: 0.5rem 1rem;
    background-color: rgba(255, 242, 0, 0.774);
    color: #374151;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid #D1D5DB;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  .btn-success:hover{
    background-color: rgba(255, 242, 0, 0.774);
  }



  .crossmark__circle.animateElement {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #ff0000;
    fill: none;
   animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .crossmark.animateElement {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #ff0000;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #ff0000;
    animation: fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .crossmark__check.animateElement {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  .cross__path.animateElement {
    stroke: #ffffff;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    transform-origin: 50% 50% 0;
  }
  .cross__path--right.animateElement {
    animation: 0.3s ease 0.8s normal forwards 1 running stroke;
  }
  .cross__path--left.animateElement {
    animation: 1s ease 0.8s normal forwards 1 running stroke;
  }
  @keyframes fillred {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ff0000;
    }
    
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  