.float{
	position:fixed;
	/* width:60px; */
	height:40px;
	bottom:140px;
	right:40px;
	background-color:#fe2600;
	color:#FFF;
	border-radius:10px;
	text-align:center;
    /* font-size:30px; */
    width: 120px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
    padding: 5px 20px;
    cursor: pointer;
	z-index: 9999;
}

.my-float{
	/* margin-top:10px; */
    /* display: block; */
    font-size: 10px;
    margin-left: 5px;
}

.small-txt
{
    font-size: 10px;
    font-weight: 700;
}
.noLinkBtn{
	text-decoration: none;
	color: #FFF;
}