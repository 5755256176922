.form-container {
    max-width: 400px;
    background-color: white;
    padding: 20px 20px;
    border-radius: 5px;
}

.page-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
